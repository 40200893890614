import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  compareListDiv,
  compareDiv,
  selectUpTo,
  comparedDevicesUl,
  comparedDevicesLi,
  compareButtonDiv,
  compareDeviceThumbnail,
  compareDeviceTitle,
  dismissIcon,
} from './styles.module.scss';
import {button, primary} from 'src/styles/modules/button.module.scss';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';
import CloseIcon from 'src/svgs/action/close.svg';
import DefaultImg from 'src/img/mdi/internet_generic_hd.png';
import {a11yHidden} from 'src/styles/modules/a11y.module.scss';

export const Comparison = ({
  devicesForComparison,
  onClearAllDevice,
  onCompare,
  onFocusFromComparison,
  onSelectToCompare,
  showOnMobile,
}) => {
  const compareButtonRef = useRef();

  useEffect(() => {
    if (devicesForComparison.length === 2) {
      compareButtonRef.current.focus();
    }
  }, [devicesForComparison]);

  return (
    <>
      {devicesForComparison.length > 0 && !showOnMobile && (
        <div
          className={compareListDiv}
          data-tracking-parent
          data-tracking='{"containerName": "Compare Device"}'
          role="region"
          aria-labelledby="compareHeading"
        >
          <div className={compareDiv}>
            <h2 className={selectUpTo} id="compareHeading">Select up to 2 devices to compare</h2>
            <div className={compareButtonDiv}>
              <button
                className={`${button} ${primary}`}
                disabled={devicesForComparison.length !== 2}
                onClick={() => {
                  onCompare(`${devicesForComparison[0].id},${devicesForComparison[1].id}`);
                  onClearAllDevice();
                }}
                ref={compareButtonRef}
              >
                <span>Compare</span>
              </button>
              <button
                className={linkButton}
                onClick={() => {
                  onFocusFromComparison(devicesForComparison.slice(-1)[0].id);
                  onClearAllDevice();
                }}
                value="clearAll"
              >
              Clear All
              </button>
            </div>
            <ul className={comparedDevicesUl}>
              {
                devicesForComparison.map((comparedDevice, index) => (
                  <li
                    className={comparedDevicesLi}
                    key={index}
                  >
                    <div className={compareDeviceThumbnail}>
                      <img src={comparedDevice.image || DefaultImg} alt={`${comparedDevice.vendorName} ${comparedDevice.baseModel}`} />
                    </div>
                    <div data-notranslate="true" className={compareDeviceTitle}>
                      <div>
                        <div>{`${comparedDevice.vendorName} ${comparedDevice.baseModel}`}</div>
                        <div>{comparedDevice.productName}</div>
                      </div>
                    </div>
                    <button
                      className={linkButton}
                      onClick={() => {
                        onSelectToCompare(comparedDevice);
                        onFocusFromComparison(comparedDevice.id);
                      }}
                      value="closeIcon"
                      aria-labelledby="removeDevice"
                    >
                      <span
                        className={a11yHidden}
                        id="removeDevice"
                      >
                      remove device
                      </span>
                      <CloseIcon className={dismissIcon} />
                    </button>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

Comparison.propTypes = {
  devicesForComparison: PropTypes.array,
  onClearAllDevice: PropTypes.func.isRequired,
  onCompare: PropTypes.func.isRequired,
  onFocusFromComparison: PropTypes.func.isRequired,
  onSelectToCompare: PropTypes.func.isRequired,
  showOnMobile: PropTypes.bool,
};
