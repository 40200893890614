import 'whatwg-fetch';
import {config} from 'src/shared/config/selectors';
import {logger} from 'src/shared/config/urls';
import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';
import {combined} from 'src/shared/params/selectors';
import {CREATE_CHANNEL_TRACKING_START} from 'src/shared/channelTracking/actions/channelTrackingAction';

const createLogFormatter = (env, page, component, uuid, anHash, cgHash) => event => {
  if (event?.detail?.data?.suppressLogging) {
    return null;
  }
  const message = {
    ...event.detail,
    _time: Date.now(),
    component,
    env,
    page,
    uuid,
    uri: window.location.href,
    locale: (typeof window?.lzGetCurrentLanguage === "function" && window?.lzGetCurrentLanguage()) || 'en-US',
  };
  if (anHash) {
    message.an_hash = anHash;
  }
  if (cgHash) {
    message.cg_hash = cgHash;
  }
  return message;
};

const createLogger = url => {
  let batch = [];
  const flush = debounce(() => {
    window.fetch(url, {method: 'POST', body: JSON.stringify(batch)});
    batch = [];
  }, 1000, {maxWait: 5000});
  return message => {
    batch.push(message);
    flush();
  };
};

const logMark = detail => {
  if (detail && detail.event === 'measure' && detail.data && detail.data.response && detail.data.response.data) { // .response.data is only available fr errors.
    detail.data.response.data = {error: JSON.stringify(detail.data.response.data.error)};
  }
  document.dispatchEvent(new CustomEvent('log', {detail, bubbles: true}));
};

const metricToJSON = metric => isFunction(metric.toJSON) ? metric.toJSON() : metric;

const collectTimings = () => {
  window.setTimeout(() => {
    window.performance?.getEntries?.()
      .filter(t => t.entryType === 'paint' || t.entryType === 'navigation')
      .map(metricToJSON)
      .forEach(logMark);
  }, 500);
};

const marks = {};
const mark = ({detail: {end, name, logData}}) => {
  const markStart = marks[name];

  if (!name || (markStart && !end) || (!markStart && end)) {
    console.warn('Invalid mark defined.');
    return;
  }

  if (!markStart) {
    const id = `${name}-${window.performance.now()}`;
    marks[name] = {id, logData};
    window.performance.mark(`${id}-start`);
    return;
  }

  const startName = `${markStart.id}-start`;
  const endName = `${markStart.id}-end`;

  window.performance.mark(endName);

  delete marks[name];
  const data = {...markStart.logData, ...logData};

  try {
    performance.measure(name, startName, endName);
    const metric = metricToJSON(performance.getEntriesByName(name).pop());
    logMark({...metric, event: 'measure', data});
  } catch (e) {
  }
};

export const defaultTypesWithCustomValues = {
  [CREATE_CHANNEL_TRACKING_START]: ({body}) => {
    const {TransactionId, action, session, interactionType} = JSON.parse(body);
    return ({
      fiveStandardsTracking: {
        transactionId: TransactionId,
        id: action.id,
        intent: action.intent,
        interactionType: interactionType,
        accountNumber: session?.customer?.accountNumber || null,
      },
    });
  },
};

export const createLoggerMiddleware = ({
  component,
  page,
  types = [],
  typesWithCustomValues = {},
  typesWithValues = [],
} = {}) => {
  const fullTypesWithCustomValues = {...defaultTypesWithCustomValues, ...typesWithCustomValues};
  return store => next => {
    if (page && component) {
      const {env, uuid} = config(store.getState());
      const {an_hash: anHash, cg_hash: cgHash} = combined(store.getState());
      const format = createLogFormatter(env, page, component, uuid, anHash, cgHash);
      const logToKibana = createLogger(logger(store.getState()));
      window.addEventListener('log', event => {
        const message = format(event);
        if (message) {
          logToKibana(message);
        }
      });
      if (window.performance.mark) {
        window.addEventListener('mark', event => {
          mark(event);
        });
      }
      collectTimings();
    }
    return action => {
      next(action);
      const {type, value} = action;
      // console.log(fullTypesWithCustomValues, type, value);
      if (fullTypesWithCustomValues[type]) {
        const message = fullTypesWithCustomValues[type](value, store.getState());
        window.dispatchEvent(new CustomEvent('log', {detail: {event: type, message}}));
      } else if (typesWithValues.includes(type)) {
        window.dispatchEvent(new CustomEvent('log', {detail: {event: type, message: value}}));
      } else if (types.includes(type)) {
        window.dispatchEvent(new CustomEvent('log', {detail: {event: type}}));
      }
    };
  };
};
