import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  compareDevicesHeader,
  compareDevicesTitleDiv,
  compareDeviceThumbnail,
  compareDeviceTitle,
  compareDevicesContent,
  compareFieldTitle,
  compareFieldTable,
  compareFieldcontent,
  deviceNotice,
  compareDevicesTitle,
} from './styles.module.scss';
import CloseIcon from 'src/svgs/action/close.svg';
import {linkButton} from 'src/styles/modules/linkButton.module.scss';
import DefaultImg from 'src/img/mdi/internet_generic_hd.png';
import {displaySpeed} from 'src/pages/deviceInfo/utils';
import {a11yHidden} from 'src/styles/modules/a11y.module.scss';

export const ComparisonView = ({
  comparedDevices,
}) => {
  const backRef = useRef();

  const onClose = () => {
    window.history.back();
    document.querySelector('xc-header').removeAttribute('aria-hidden');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelector('xc-header').setAttribute('aria-hidden', true);
    document.querySelector('h1').focus();
  }, []);

  return (
    <>
      <div className={compareDevicesHeader}>
        <div className={compareDevicesTitle}>
          <h1 tabIndex="-1">Comparing 2 Devices</h1>
          <button
            className={linkButton}
            onClick={onClose}
            ref={backRef}
            aria-labelledby="backOnComparisonView"
          >
            <span
              id="backOnComparisonView"
              className={a11yHidden}
            >
            close
            </span>
            <CloseIcon />
          </button>
        </div>
        <div
          className={compareDevicesTitleDiv}
          aria-hidden="true"
        >
          {
            comparedDevices.map((device, index) => (
              <div key={index}>
                <div className={compareDeviceThumbnail}>
                  <img src={device.image || DefaultImg} alt={`${device.vendorName} ${device.baseModel}`} />
                </div>
                <div data-notranslate="true" className={compareDeviceTitle}>
                  <div>
                    <div>{device.vendorName} {device.baseModel}</div>
                    <div>{device.productName}</div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div
        className={compareDevicesContent}
        role="table"
      >
        <div role="row" className={a11yHidden}>
          <div role="columnheader">
          Feature
          </div>
          {
            comparedDevices.map((device, index) => (
              <div role="columnheader" key={index}>
                {device.vendorName} {device.baseModel} {device.productName}
              </div>
            ))
          }
        </div>
        <div className={compareFieldTable} role="row">
          <div className={compareFieldTitle} role="rowheader">Device Type</div>
          <div className={compareFieldcontent}>
            {
              comparedDevices.map((device, index) => (
                <div key={index} role="cell">{device.deviceType}</div>
              ))
            }
          </div>
        </div>
        <div className={compareFieldTable} role="row">
          <div className={compareFieldTitle} role="rowheader">Wired Download Speeds</div>
          <div className={compareFieldcontent}>
            {
              comparedDevices.map((device, index) => (
                <div key={index} role="cell">
                  {device && `Up to ${displaySpeed(Math.floor(device.dsSpeed) / 1e6)}`}
                </div>
              ))
            }
          </div>
        </div>
        <div className={compareFieldTable} role="row">
          <div className={compareFieldTitle} role="rowheader">Built-in WiFi</div>
          <div className={compareFieldcontent}>
            {
              comparedDevices.map((device, index) => (
                <div key={index} role="cell">{device.wifi === 0 ? 'No' : 'Yes'}</div>
              ))
            }
          </div>
        </div>
        <div className={compareFieldTable} role="row">
          <div className={compareFieldTitle} role="rowheader">WiFi (2.4 GHz) Download Speed</div>
          <div className={compareFieldcontent}>
            {
              comparedDevices.map((device, index) => (
                <div key={index} role="cell">
                  {device.dsSpeedWifi24 === null ? 'NA'
                    : device.dsSpeedWifi24 >= 10e8
                      ? `Up to ${device.dsSpeedWifi24.toString().slice(0, -9)} Gbps`
                      : `Up to ${device.dsSpeedWifi24.toString().slice(0, -6)} Mbps`}
                </div>
              ))
            }
          </div>
        </div>
        <div className={compareFieldTable} role="row">
          <div className={compareFieldTitle} role="rowheader">WiFi (5 GHz) Download Speed</div>
          <div className={compareFieldcontent}>
            {
              comparedDevices.map((device, index) => (
                <div key={index} role="cell">
                  {device.dsSpeedWifi50 === null ? 'NA'
                    : device.dsSpeedWifi50 >= 10e8
                      ? `Up to ${device.dsSpeedWifi50.toString().slice(0, -9)} Gbps`
                      : `Up to ${device.dsSpeedWifi50.toString().slice(0, -6)} Mbps`}
                </div>
              ))
            }
          </div>
        </div>
        <div className={compareFieldTable} role="row">
          <div className={compareFieldTitle} role="rowheader">Voice/Telephone enabled</div>
          <div className={compareFieldcontent}>
            {
              comparedDevices.map((device, index) => (
                <div key={index} role="cell">
                  {device.voice === 0 ? 'No' : 'Yes'}
                </div>
              ))
            }
          </div>
        </div>
        <div className={compareFieldTable} role="row">
          <div className={compareFieldTitle} role="rowheader">DOCSIS Channels</div>
          <div className={compareFieldcontent}>
            {
              comparedDevices.map((device, index) => (
                <div key={index} role="cell">
                  {device.docsisChannel}
                </div>
              ))
            }
          </div>
        </div>
        <div className={compareFieldTable} role="row">
          <div className={compareFieldTitle} role="rowheader">DOCSIS Version</div>
          <div className={compareFieldcontent}>
            {
              comparedDevices.map((device, index) => (
                <div key={index} role="cell">
                  {`${device.docsisVersion.slice(0, 6)} ${device.docsisVersion.slice(6)}`}
                </div>
              ))
            }
          </div>
        </div>
        <div className={compareFieldTable} role="row">
          <div className={compareFieldTitle} role="rowheader">IPv6</div>
          <div className={compareFieldcontent}>
            {
              comparedDevices.map((device, index) => (
                <div key={index} role="cell">
                  {device.ipv6 === 0 ? 'No' : 'Yes'}
                </div>
              ))
            }
          </div>
        </div>
        <div className={deviceNotice} aria-hidden="true">The products on this site have been certified by CableLabs, Underwriters Laboratory (&quot;UL&quot;), Federal Communications Commission (FCC) and (if applicable) the WiFi Alliance. In addition, we test DOCSIS and IPv4/6 to confirm successful connections to our network. Depending on the product, we may have also tested other features (such as Xfinity Voice).</div>
      </div>
    </>
  );
};

ComparisonView.propTypes = {
  comparedDevices: PropTypes.array,
};
