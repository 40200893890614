import {AUTH} from 'src/shared/auth/actions';
import {
  isAuthenticated,
} from 'src/shared/auth/selectors';
import {waitUntil} from 'src/shared/events';
import {errorToJSON} from 'src/shared/utils/errorToJSON';
import segments from 'src/shared/datacast/segments.json';

export const READ_DATACAST_START = 'READ_DATACAST_START';
export const READ_DATACAST_SUCCESS = 'READ_DATACAST_SUCCESS';
export const READ_DATACAST_ERROR = 'READ_DATACAST_ERROR';
export const READ_DATACAST_COMPLETE = 'READ_DATACAST_COMPLETE';

export const readDatacastStart = tags => ({
  type: READ_DATACAST_START,
  value: {tags},
});

export const readDatacastSuccess = (segments, tags) => ({
  type: READ_DATACAST_SUCCESS,
  value: {segments, tags},
});

export const readDatacastError = (error, tags) => ({
  type: READ_DATACAST_ERROR,
  value: {error: errorToJSON(error), tags},
});

export const readDatacastComplete = tags => ({
  type: READ_DATACAST_COMPLETE,
  value: {tags},
});

export const datacast = (...tags) => async (dispatch, getState) => {
  if (tags.length === 0) {
    tags.push('helpandsupport');
  }

  dispatch(readDatacastStart(tags));

  await waitUntil(AUTH);
  if (!isAuthenticated(getState())) {
    dispatch(readDatacastError(new Error('User is not authenticated.'), tags));
    dispatch(readDatacastComplete(tags));
    return;
  }

  try {
    dispatch(readDatacastSuccess(segments?.data, tags));
  } catch (error) {
    dispatch(readDatacastError(error, tags));
  } finally {
    dispatch(readDatacastComplete(tags));
  }
};
